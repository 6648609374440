<template>
  <div
      class="has-background-white">
    <b-navbar>
      <template #brand>
        <b-navbar-item
            tag="router-link"
            :to="{ path: '/' }"
        >
          <img
              src="https://www.linctechonline.com/wp-content/themes/linchtechis172/images/main-logo.png"
              alt="LincTech Global Logistics"
          >
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item href="#">
          <b>Shipment Tracking</b>
        </b-navbar-item>
      </template>
    </b-navbar>

    <section class="hero is-medium hero-background">
      <div class="hero-body">
        <p class="title">Track Your Shipment</p>
        <p class="subtitle">Conveniently keep track of your shipment by using our shipment tracker now.</p>
      </div>
    </section>

    <div class="container p-4" style="min-height: 70vh">
      <router-view />
    </div>

    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          <strong>LincTech Global Logistics</strong>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'FullPage',
  created () {
    this.$store.dispatch('toggleFullPage', true)
  },
  beforeDestroy () {
    this.$store.dispatch('toggleFullPage', false)
  }
}
</script>

>
<style>
.hero-background {
  background-image: url('https://linctechonline.com/wp-content/uploads/2023/02/non-home-632424716.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
